/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'diagnostic': {
    width: 512,
    height: 512,
    viewBox: '0 0 512 512.001',
    data: '<path pid="0" d="M437 182c-41.355 0-75 33.645-75 75 0 36.219 25.805 66.523 60 73.492v42.758C422 433.215 373.215 482 313.25 482 248.598 482 196 429.852 196 365.75v-32.813c9.422-3.308 17.578-9.707 23.09-18.476l50.746-79.383c.129-.203.234-.414.355-.621C321.727 216.309 362 165.844 362 105c0-36.219-25.809-66.523-60-73.492V15c0-8.285-6.719-15-15-15-8.285 0-15 6.715-15 15v60c0 8.285 6.715 15 15 15 8.281 0 15-6.715 15-15V62.578c17.457 6.195 30 22.867 30 42.422 0 58-48.543 107-106 107h-91c-59.797 0-106.832-51.566-104.945-110.457C30.629 83.625 43.09 68.32 60 62.508V75c0 8.285 6.715 15 15 15 8.281 0 15-6.715 15-15V15c0-8.285-6.719-15-15-15-8.285 0-15 6.715-15 15v16.512C26.863 38.222 1.156 66.64.07 100.582c-1.933 60.367 36.434 115.207 91.97 134.277.042.075.077.149.124.22l50.742 79.382c5.516 8.77 13.672 15.168 23.094 18.48v32.809C166 446.395 232.055 512 313.25 512 389.758 512 452 449.758 452 373.25v-42.758c34.191-6.969 60-37.27 60-73.492 0-41.355-33.648-75-75-75zm-304.832 59.965c.941.02 1.887.035 2.832.035h91c1.285 0 2.563-.023 3.844-.059l-36.063 56.414c-.023.04-.047.075-.07.114-3.98 6.347-10.215 7.035-12.711 7.035s-8.73-.688-12.71-7.035c-.024-.04-.048-.074-.071-.114zM437 302c-24.816 0-45-20.188-45-45s20.184-45 45-45c24.813 0 45 20.188 45 45s-20.188 45-45 45zm0 0"/><path pid="1" d="M437 227c-16.543 0-30 13.457-30 30s13.457 30 30 30c16.54 0 30-13.457 30-30s-13.46-30-30-30zm0 0"/>'
  }
})
